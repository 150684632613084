.appointment__action-bar--split {
    background-color: $neutral-button-color;
    margin-right: 20px;
}

.appointment__action-bar--split:hover {
    @include darken-button($neutral-button-color, $normal-button-text-color);
}

.appointment__action-bar--delete {
    background-color: $alert-button-color;
}

.appointment__action-bar--delete:hover {
    @include darken-button($alert-button-color, $normal-button-text-color);
}

.appointment__nav--container {
    width: 100%;
    button {
        @extend %button;
        box-shadow: none;
        outline: none;
        background-color: $normal-button-color;
        border-color: transparent;
        color: $normal-button-text-color;
        min-height: 50px;
        min-width: 33%;
        border-left: $main-text-color $small-border-width solid;
        p {
            display: inline;
            margin-left: 10px;
            padding: 5px 10px;
            border-radius: $tiny-border-radius;
            background-color: $alert-button-color;
        }
    }
    button:hover {
        @include darken-button($normal-button-color, $normal-button-text-color);
    }
}

@media (max-width:500px) {
    .appointment__nav--container {
        button {
            font-size: $smallest-font-size;
        }
    }
}

#general-info-button {
    border: none;
}

.appointment__map--img {
    width: 100%;
}

.appointment__address--container {
    #business-name {
        margin-top: 5px;
        font-weight: bold;
    }
    #business-website {
        display: block;
        margin-bottom: 5px;
    }
    #business-email {
        display: block;
        margin-bottom: 5px;
    }
}

.appointment__doctor--name {
    font-size: $large-font-size;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.appointment__doctor--speciality {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.appointment__appointment--time-notice {
    font-size: $smallest-font-size;
    margin-left: 15px;
}

.appointment__doctor-appointment--container {
    border-left: solid $small-border-width $light-border-color;
    padding-left: 100px;
}

@media (max-width:400px) {
    .appointment__doctor-appointment--container {
        margin-top: 20px;
    }
}

.appointment__pending-button--container {
    margin: 20px 0;
    background-color: $normal-button-color;
    border: none;
    border-radius: $small-border-radius;
    text-align: center;
    width: 70%;
    min-width: 300px;
    min-height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $normal-button-text-color;
}

.appointment__pending-button--container:hover {
    @extend %button;
    @include darken-button($normal-button-color, $normal-button-text-color);
    text-decoration: none;
    color: $normal-button-text-color;
}

.appointment__service--name {
    font-size: $medium-font-size;
}

.appointment__service--price {
    font-size: $medium-font-size;
    display: inline;
    margin-right: 20px;
}

.appointment__services--bought {
    font-size: $small-font-size;
}

.appointment__pending--buttons {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.appointment__reminders--container {
    margin-top: 50px;
    label {
        span {
            font-weight: normal;
        }
    }
}