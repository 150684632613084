.pending__container {
    max-width: 50%;
}

.pending__container--item {
    @extend %button;
    border-bottom: solid $light-border-color $small-border-width;
    padding: 10px;
}

.pending__container--item:hover {
    @include darken-button(white, $main-text-color);
}

.pending__text--name {
    font-weight: bold;
}

.pending__text--no-comments {
    font-size: $smaller-font-size;
    font-style: italic;
}