.settings__container {
    width: 100%;
    display: flex;
}

.settings__item {
    text-align: center;
    font-size: 1.5em;
    border: $light-border-color solid $normal-border-width;
    border-radius: $normal-border-radius;
    padding: 20px;
    width: 100%;
    max-width: 200px;
    color: $medium-border-color;
    margin: 50px;
    text-decoration: none;
}

@media(max-width:400px) {
    .settings__container {
        flex-direction: column;
        align-items: center;
    }
    .settings__item {
        margin: 30px;
    }
}

.settings__item:hover {
    cursor: pointer;
    text-decoration: none;
    color: darken($medium-border-color, 20%);
    border-color: darken($medium-border-color, 20%);
}

.settings__item--link:hover {
    text-decoration: none;
    color: inherit;
}

.settings__item--icon {
    font-size: 3.5em;
    margin-bottom: 30px;
    text-decoration: none;
}

.settings__list--container {
    margin: 20px;
    padding: 20px 0;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
}

.reports__list--container {
    margin: 0 20px;
    padding: 20px 0;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
}

.reports__list--item:hover,
.reports__list--item:visited,
.reports__list--item:active {
    border: 2px solid darken(#fff, 10%);
    color: $main-text-color;
    text-decoration: none;
    cursor: pointer;
}

.reports__list--item {
    border-radius: $small-border-radius;
    padding: 10px;
    border-bottom: 1px solid darken(#fff, 10%);
    margin-top: 20px;
}

.reports__list--title {
    font-size: 1.5em;
}

.reports__list--description {
    color: lighten($main-text-color, 10%);
    font-size: 1em;
}

.settings__list--item {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-bottom: $light-border-color $small-border-width solid;
    padding: 10px 0;
    button {
        @extend %button;
        @extend %normal-button;
        @include shadow-rectangle;
        min-width: 10%;
        min-height: 40px;
    }
    button:hover {
        @include darken-button($normal-button-color, $normal-button-text-color);
        @include shadow-rectangle-hover;
    }
}

.settings__list--docNameSpeciality {
    margin-right: 30px;
    width: 18%;
    text-align: right;
    @include word-break-ellipsis;
    @include capitalize-each-word;
}

.settings__list--docName {
    font-size: $medium-font-size;
    padding-bottom: 5px;
}

.settings__list--docSpeciality {
    font-size: $small-font-size;
    font-style: italic;
}

.settings__list--actionIcons {
    @extend %button;
    font-size: $large-font-size;
}

.settings__list--update {
    color: $normal-button-color;
    margin-right: 10px;
    :hover {
        color: darken($normal-button-color, $hover-darken-amount);
    }
}

.settings__list--delete {
    color: $alert-button-color;
    margin-left: 10px;
    :hover {
        color: darken($alert-button-color, $hover-darken-amount);
    }
}

.pending__list--delete {
    font-size:medium;
    color: $alert-button-color;
    margin-left: 10px;
    :hover {
        color: darken($alert-button-color, $hover-darken-amount);
    }
}

.fields_settings__form--fieldset {
    min-width: 30%;
}

.field_settings__form--field-name {
    font-size: $medium-font-size;
    font-style: italic;
}

.field_settings__form--field-title {
    margin-left: 50px;
    font-size: $medium-font-size;
    font-weight: bold;
}

.fields_settings__form--field-title-container {
    display: flex;
    justify-content: center;
}

.fields_settings__form--row {
    width: 50%;
}

.billing-settings__services--col {
    border: solid $light-border-color $normal-border-width;
    border-radius: $normal-border-radius;
    padding-bottom: 20px;
}

.billing-settings__form--item {
    margin-top: 30px;
    padding-bottom: 10px;
    border-bottom: solid $light-border-color $normal-border-width;
}

#visible,
#required {
    margin-left: 15px;
    max-width: 25px;
}