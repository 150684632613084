.pages__action-bar--container {
    display: flex;
    justify-content: flex-end;
    margin: 0 30px 10px auto;
    max-width: 100%;
    max-height: 70px;
    padding: 0;
}

@media(max-width:350px) {
    .pages__action-bar--container {
        margin-right: 10px;
    }
}

.pages__action-bar--item {
    @extend %button;
    @extend %normal-button;
    @include shadow-circle;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $round-border-radius;
    width: 60px;
    height: 60px;
}

.pages__action-bar--item:hover {
    @include shadow-circle-hover;
    @include darken-button($normal-button-color, $normal-button-text-color);
}

.pages__header--container {
    margin: 20px;
    h1 {
        font-size: $largest-font-size;
    }
}

.pages__modal--container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 30px 30px 30px;
    padding: 0 30px 30px 30px;
}

.pages__form {
    min-width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pages__form--fieldset {
    margin-top: 30px;
}

.pages__form--item {
    width: 50%;
    margin: 10px 0;
    input {
        width: 100%;
    }
}

.pages__form--item-label {
    margin-right: 15px;
}

.save-button {
    @extend %button;
    @extend %normal-button;
    @include shadow-circle;
    margin-top: 5px;
    width: 40%;
    min-height: 35px;
}

.save-button:hover {
    @include shadow-circle-hover;
    @include darken-button($normal-button-color, $normal-button-text-color);
}

.small-button {
    width: 20%;
}

.medium-button {
    width: 30%;
}

.large-button {
    width: 60%;
}

.pages__back-button--container {
    display: flex;
    align-items: center;
}

.pages__back-button--icon {
    font-size: $large-font-size;
    margin: 0 10px 0 20px;
    color: $normal-button-color;
}

.pages__back-button--text {
    @extend %button;
    @include reset-link;
    font-size: $medium-font-size;
    color: $main-text-color;
}

.pages__back-button--text:hover {
    @include reset-link;
    color: darken($main-text-color, 50%);
}

.pages__form--error {
    color: $alert-text-color;
    text-align: center;
    margin-top: 20px;
}

.centered-text {
    float: none;
    text-align: center;
}

.right-text {
    float: none;
    text-align: right;
}

.pages__form--col {
    padding-bottom: 10px;
    padding-right: 20px;
    border-bottom: $light-border-color solid $normal-border-width;
}

.no-border {
    border: none;
}

.pages__form--row {
    margin-bottom: 50px;
    display: flex;
    align-items: center;
}

.full-width {
    width: 100%;
}

.tiny-push {
    margin: 10px;
}

.small-push {
    margin: 30px;
}

.medium-push {
    margin: 60px;
}

.large-push {
    margin: 100px;
}

.neutral-button {
    background-color: $light-border-color;
    margin: 5px;
}

.neutral-button:hover {
    @include darken-button($light-border-color, $normal-button-text-color);
}

.neutral-button-2 {
    background-color: $neutral-button-color;
    margin: 5px;
}

.neutral-button-2:hover {
    @include darken-button($neutral-button-color, $normal-button-text-color);
}

.my-button {
    @include shadow-rectangle;
    width: 100px;
    height: 30px;
    border: $main-text-color solid 1px;
    border-radius: $normal-border-radius;
    background: white;
    margin-top: 15px;
    margin-right: 10px;
    outline: none;
}

.my-button:hover {
    @include shadow-rectangle-hover;
    border-color: darken($normal-button-color, 50%);
    color: darken($normal-button-color, 50%);
}

.flex-row {
    display: flex;
    flex-direction: row;
}