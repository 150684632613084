@mixin word-break-hyphens {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
}

@mixin word-break-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

%button {
    cursor: pointer;
    outline: none;
}

%normal-button {
    border-color: transparent;
    border-radius: $small-border-radius;
    background-color: $normal-button-color;
    color: $normal-button-text-color;
    font-size: $small-font-size;
}

@mixin darken-button($background-color, $text-color) {
    background-color: darken($background-color, $hover-darken-amount);
    color: $text-color;
}

@mixin reset-link {
    text-decoration: unset;
}

@mixin shadow-circle {
    box-shadow: 0 3px 10px 1px rgba(0, 0, 0, .15);
}

@mixin shadow-rectangle {
    box-shadow: 0 3px 5px 1px rgba(0, 0, 0, .1);
}

@mixin shadow-circle-hover {
    box-shadow: 0 6px 10px 1px rgba(0, 0, 0, .2);
}

@mixin shadow-rectangle-hover {
    box-shadow: 0 6px 5px 1px rgba(0, 0, 0, .15);
}

@mixin capitalize-each-word {
    text-transform: lowercase;
    text-transform: capitalize;
}