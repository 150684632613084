.calendar__doctor-lists--container {
    text-align: center;
    color: $main-text-color;
}

.calendar__doctor-lists--list {
    min-height: 250px;
    max-height: 280px;
    overflow-y: scroll;
    overflow-x: hidden;
}

@mixin calendar__doctor-lists--item {
    @extend %button;
    @include shadow-rectangle;
    max-height: 60px;
    border: transparent;
    border-radius: $super-tiny-border-radius;
    padding: 10px;
    margin: 3px;
    margin-bottom: 10px;
}

.calendar__doctor-lists--item-inactive {
    @include calendar__doctor-lists--item;
    background: $light-shade;
}

.calendar__doctor-lists--item-active {
    @include calendar__doctor-lists--item;
    color: $normal-button-text-color;
    background: $neutral-button-color;
}

.calendar__doctor-lists--item-inactive:hover {
    @include shadow-rectangle-hover;
    @include darken-button($light-shade, $main-text-color);
}

.calendar__doctor-lists--item-active:hover {
    @include shadow-rectangle-hover;
    @include darken-button($neutral-button-color, $normal-button-text-color);
}

.calendar__doctor-lists--item-text {
    font-size: $medium-font-size;
    margin: 0;
    @include word-break-ellipsis;
    @include capitalize-each-word;
}

.calendar__doctor-lists--item-subtext {
    font-size: $small-font-size;
    font-style: italic;
    margin: 0;
    @include word-break-ellipsis;
    @include capitalize-each-word;
}

.appointment-list__container {
    @extend %button;
}

.calendar__container {
    height: 600px;
    margin: 5px 20px 20px 20px;
}

.calendar__slots--filled {
    background-color: $alert-text-color;
}

.calendar__slots--available {
    background-color: $available-slot-color;
}

.rbc-header {
    height: 40px;
    span {
        font-size: $large-font-size;
    }
}

.rbc-allday-cell {
    display: none;
    height: 0;
}

.rbc-toolbar-label {
    font-size: $medium-font-size;
}

.rbc-agenda-date-cell {
    background-color: white;
    color: $main-text-color;
}

.rbc-agenda-date-cell,
.rbc-agenda-time-cell,
.rbc-agenda-event-cell {
    border: $main-text-color solid $small-border-width;
}

.calendar__agenda--functions-container {
    display: flex;
    align-items: center;
}

.calendar__agenda--print-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
}

.calendar__agenda--print-icon {
    color: $main-text-color;
    font-size: $large-font-size;
    padding: 5px;
    @extend %button;
}

.calendar__agenda--print-icon:hover {
    color: darken($main-text-color, 50%);
    background-color: lighten($main-text-color, 75%);
    border: transparent;
    border-radius: 50%;
}

.toolbar-container {
    display: flex;
    justify-content: space-between;
}

@media (max-width:600px) {
    .toolbar-container {
        flex-direction: column;
    }
}

.calendar__toolbar--nav-buttons {
    background: none;
}

@mixin calendar__toolbar--button {
    @extend %button;
    border: none;
}

.calendar__toolbar--search-button {
    @include calendar__toolbar--button;
    background: transparent;
    color: $disabled-button-color;
    font-size: 25px;
    margin-right: 20px;
}

.calendar__toolbar--search-button:hover {
    background-color: $light-shade;
    border-radius: $round-border-radius;
    padding: 5px 10px;
}

.calendar__toolbar--today-button,
.calendar__toolbar--search-start-button,
.calendar__toolbar--first-availability-button {
    @include calendar__toolbar--button;
    background-color: #F5F5F5;
    border-radius: 2px;
    padding: 5px 15px;
}

.calendar__toolbar--today-button:hover,
.calendar__toolbar--search-start-button:hover,
.calendar__toolbar--first-availability-button:hover {
    background-color: #EBEBEB;
}

.calendar__toolbar--first-availability-button {
    min-width: 150px;
    max-height: 40px;
    margin: 0 10px;
}

.calendar__toolbar--back-button,
.calendar__toolbar--next-button {
    @include calendar__toolbar--button;
    background: none;
    padding: 5px 15px;
    font-size: $large-font-size;
    color: lighten($main-text-color, 40%);
    margin-left: 10px;
}

.calendar__toolbar--back-button:hover,
.calendar__toolbar--next-button:hover {
    background-color: #EBEBEB;
}

.calendar__toolbar--label {
    font-size: $medium-font-size;
    font-weight: normal;
    color: lighten($main-text-color, 40%);
    margin-left: 10px;
}

.calendar__toolbar--view {
    display: flex;
    flex-direction: row;
    align-content: space-between;
    max-width: 1000px;
    select {
        border: none;
        border-radius: 5px;
        box-shadow: none;
        background-color: #F5F5F5;
    }
}

.calendar__billing--totals-cell {
    border: 1px solid lightgray;
    font-size: $medium-font-size;
    font-weight: 900;
    padding: 0 0 0 10px;
}

.calendar__no-doctor--container {
    min-height: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: $largest-font-size;
}

.calendar__doctor-title--text {
    font-size: $medium-font-size;
    text-align: center;
    span {
        @media (min-width: 400px) {
            font-size: $large-font-size;
            font-weight: 700;
        }
        @media (max-width:400px) {
            font-size: $medium-font-size;
            font-weight: 700;
        }
    }
}

.calendar__popup--container,
.calendar__availability-popup--container {
    background-color: $popup-background-color;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    z-index: 1001;
    justify-content: center;
}

.calendar__popup--popup,
.calendar__availability-popup--popup {
    background-color: white;
	min-width:350px;
	max-width: 600px;
    position: fixed;
    border-radius: $small-border-radius;
}

@mixin calendar__popup--button {
    margin-top: 15px;
    background-color: transparent;
    border: solid $small-border-width $main-text-color;
    border-radius: $round-border-radius;
    color: $main-text-color;
    span {
        display: inline-block;
        width: 1rem;
        height: 1rem;
        border-radius: $round-border-radius;
        margin-left: 20px;
    }
}

.calendar__popup--reset-button {
    @include calendar__popup--button;
    span {
        margin-left: 65px;
        background-color: $normal-button-color;
    }
}

.calendar__popup--reset-button:hover {
    background-color: transparent;
    color: $main-text-color;
}

.calendar__popup--available-button {
    @include calendar__popup--button;
    span {
        background-color: $neutral-button-color;
    }
}

.calendar__popup--available-button:hover {
    background-color: transparent;
    color: $main-text-color;
}

.calendar__popup--blocked-button {
    @include calendar__popup--button;
    span {
        background-color: $alert-button-color;
        margin-left: 30px;
    }
}

.calendar__popup--blocked-button:hover {
    background-color: transparent;
    color: $main-text-color;
}

.calendar__popup--new-appointment-button {
    @include calendar__popup--button;
    span {
        background-color: $available-slot-color;
    }
}

.calendar__popup--new-appointment-button:hover {
    background-color: transparent;
    color: $main-text-color;
}

.calendar__popup--cut-appointment-button {
    @include calendar__popup--button;
    span {
        background-color: #F87D00;
    }
}

.calendar__popup--cut-appointment-button:hover {
    background-color: transparent;
    color: $main-text-color;
}

.calendar__popup--cancel-button {
    margin-top: 15px;
    text-align: center;
    color: $main-text-color;
    text-decoration: underline;
    cursor: pointer;
}

.calendar__popup--date,
.calendar__availability-popup--title,
.calendar__availability-popup--sub-title {
    text-align: center;
    margin-top: 10px;
}

.calendar__availability-popup--title {
    font-size: $medium-font-size;
}

.calendar__availability-popup--sub-title {
    font-size: $smaller-font-size;
    color: lighten($main-text-color, 50%);
    font-style: italic;
    padding: 0 5px;
}

.calendar__availability-popup-item {
    @extend %button;
    text-align: center;
    padding-top: 10px;
    border-bottom: $light-border-color solid $small-border-width;
}

.calendar__availability-popup-item:hover {
    background-color: darken(white, $hover-darken-amount)
}

.disabled {
    cursor: not-allowed;
    background-color: $disabled-button-color;
}

.disabled:hover {
    cursor: not-allowed;
    background-color: $disabled-button-color;
}

.calendar__toolbar--search-bar {
    width: 400px !important;
    margin-right: 20px;
}

@media (max-width:400px) {
    .calendar__toolbar--search-bar {
        width: 150px !important;
    }
}

.calendar__toolbar--views {
    width: 100px;
    min-height: 40px;
}

.calendar__toolbar--search-start-button {
    margin-right: 20px;
}

.calendar__toolbar--search-cancel-button {
    margin-right: 50px;
    font-size: 20px;
    cursor: pointer;
}

.calendar__toolbar--search-result-container {
    border-top: none !important;
}

.calendar__toolbar--search-result {
    padding: 0 5px 10px 5px;
    border-radius: 5px;
    border-bottom: 1px gray solid;
}

.calendar__toolbar--search-result:hover {
    cursor: pointer;
    background-color: $light-shade;
    padding: 0 5px 10px 5px;
}

.calendar__sidebar--toggle {
    cursor: pointer;
    font-size: $large-font-size;
    text-align: right;
}