// @import 'reset';
@import 'variables';
@import 'mixins';
@import 'common';
@import 'header';
@import 'footer';
@import 'settings';
@import 'calendar';
@import 'todos';
@import 'availability';
@import 'appointment';
@import 'pending';
@import 'react-datepicker';
@import 'reports';
html {
    position: relative;
    min-height: 100%;
}

body {
    font-family: $main-font-family;
    color: $main-text-color;
}

.inherit-all {
    color: inherit;
    text-decoration: inherit;
}

/* width */

::-webkit-scrollbar {
    width: 5px;
}

/* Track */

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */

::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

@media print {
    .noprint {
        display: none !important;
    }
    button {
        display: none !important;
    }
}