.reports__container {
    border: 1px black solid;
    margin: 20px;
    margin-top: 70px;
    td,
    th,
    tr {
        padding: 10px;
        border: 1px black solid;
    }
}

.reports__nodata {
    margin-top: 200px;
    font-size: 1.5em;
    text-align: center;
    font-style: italic;
    color: lighten($main-text-color, 20%);
}

.reports__date {
    margin-top: 20px;
    text-align: center;
}