.availability__container {
    width: 25%;
}

.availability__mark-buttons--container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
        margin: 40px 0 20px 0;
    }
}

.availability__mark-buttons--buttons {
    margin-left: 10px;
}

@mixin availability__mark-buttons--button {
    @extend %button;
    box-shadow: none;
    border-color: transparent;
    border-radius: $small-border-radius;
    margin-left: 10px;
    width: 100px;
    height: 30px;
    color: $normal-button-text-color;
    outline: none;
}

.availability__mark-buttons--button-blocked {
    @include availability__mark-buttons--button;
    background-color: $alert-button-color;
}

.availability__mark-buttons--button-blocked:hover {
    @include darken-button($alert-button-color, $normal-button-text-color);
}

.availability__mark-buttons--button-available {
    @include availability__mark-buttons--button;
    background-color: $neutral-button-color;
    margin: 0;
}

.availability__mark-buttons--button-available:hover {
    @include darken-button($neutral-button-color, $normal-button-text-color);
}