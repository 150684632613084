.todo__list--item {
    @extend %button;
    background: $todo-item-color;
    border-bottom: $light-border-color solid $small-border-width;
    padding: 20px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 30px;
    border-radius: 2px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    transition: box-shadow 0.2s;
}

@media(max-width:350px) {
    .todo__list--item {
        margin-right: 10px;
    }
}

.todo__list--item:hover {
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.30);
}

.todo__list--text {
    overflow-wrap: break-word;
}

.todo__list--subtext {
    font-style: italic;
    font-size: $smaller-font-size;
    color: lighten($main-text-color, 50%);
    margin-top: 20px;
}

.todos__new--container {
    margin: 30px;
    max-width: 50%;
}

.settings__list--no-tasks-text {
    margin-top: 50px;
    font-style: italic;
}

.settings__list--no-tasks-text {
    display: flex;
    justify-content: center;
}

#todos-number {
    display: inline;
    margin-left: 10px;
    padding: 2px 6px;
    border-radius: $round-border-radius;
    background-color: $alert-button-color;
    color: white;
    text-align: center;
}