$main-text-color:#343434;
$alert-text-color:#FE3636;
$normal-button-color:#189AD3;
$alert-button-color:red;
$available-slot-color:#F6BF26;
$neutral-button-color:#19d477;
$normal-button-text-color:white;
$disabled-button-color:darkgray;
 $main-font-family: "Helvetica Neue",
Helvetica,
Arial,
sans-serif;
$smallest-font-size:0.7em;
$smaller-font-size:0.9em;
$small-font-size:1em;
$medium-font-size:1.2em;
$large-font-size:1.6em;
$largest-font-size:2em;
$light-border-color:lighten( $main-text-color, 40%);
$medium-border-color:lighten( $main-text-color, 20%);
$small-border-width: 1px;
$normal-border-width: 2px;
$super-tiny-border-radius:3px;
$tiny-border-radius:5px;
$small-border-radius:10px;
$round-border-radius:999px;
$normal-border-radius: 20px;
$hover-darken-amount: 5%;
$todo-item-color:white;
$light-shade:darken(white, 5%);
$popup-background-color:rgba(70, 70, 70, 0.6);