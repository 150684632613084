.header__logo--container {
    text-align: center;
    margin-bottom: 20px;
    img {
        margin-top: 20px;
        max-width: 180px;
    }
}

@media (max-width:480px) {
    .header__logo--container {
        margin-left: 150px;
    }
}

.header__logout--container {
    text-align: right;
    margin-right: 60px;
}